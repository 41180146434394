export type GAEventName = 'ui_click' | 'ui_screen_visit' | 'login' | 'accept_lead' | 'update lead';

export type GAEventType = 'Click' | 'Screen' | 'Backend';

export type GAModule =
  | 'Auth'
  | 'Error'
  | 'Application'
  | 'Home'
  | 'PRULeads'
  | 'AgentProfile'
  | 'InsuranceSummaries'
  | 'Summary'
  | 'Survey'
  | 'ManagerDashboard'
  | 'PRULeads'
  | 'AI Talkbot';

export type GAFeature =
  | 'NA'
  | 'AgentProfileSite'
  | 'Error'
  | 'Login'
  | 'ACM'
  | 'ManagerDashboardPRULeads'
  | 'Dashboard'
  | 'Download'
  | 'InsuranceSummary'
  | 'AgentSurvey'
  | 'CustomerSurvey'
  | 'Lead'
  | '“Lead”'
  | 'Appointment'
  | 'lead_status'
  | 'Leads'
  | 'Prospect'
  | 'Campaign'
  | 'Insights'
  | 'Insight'
  | 'My Task'
  | 'AI Talkbot';

export type GAJourney =
  | 'NA'
  | 'agent_login'
  | 'create_agent_profile'
  | 'edit_agent_profile'
  | 'overview'
  | 'prospect_category'
  | 'prospect_source'
  | 'add_new_agency_campaign'
  | 'view_all_campaigns'
  | 'view_campaign_detail'
  | 'view_dashboard'
  | 'download_report'
  | 'download_application'
  | 'isr_form'
  | 'isr_dashboard'
  | 'fill_agt_survey'
  | 'preview_cus_survey'
  | 'cus_survey_result'
  | 'PRULeads Home'
  | 'Accept New Lead'
  | 'Confirm Contact Lead'
  | 'Update Lead'
  | 'Check Close Lead'
  | 'opportunity'
  | 'Follow Up'
  | 'View Appointment'
  | 'Add Appointment'
  | 'View Sales Lead'
  | 'Edit Sales Lead'
  | 'Reassign Lead'
  | 'switch_accept_lead_option'
  | 'Add Lead'
  | 'View Lead'
  | 'View Prospect'
  | 'Edit Prospect'
  | 'Search Prospect'
  | 'Delete Prospect'
  | 'Add Prospect'
  | 'Import Existing Customer As Prospect'
  | 'Merge Prospect'
  | 'Edit Category'
  | 'searches_campaign'
  | 'View Marketing Campaign'
  | 'View Mass Campaign'
  | 'View Agency Campaign'
  | 'Host Agency Campaign'
  | 'View Servicing Campaign'
  | 'Insight Overview'
  | 'Insight Lead Status'
  | 'Insight Marketing Lead'
  | 'Insight Mass Campaign'
  | 'Insight Agency Campaign Lead'
  | 'Insight Prospect'
  | 'Insight Appointment'
  | 'Insight Servicing Campaign'
  | 'Insight Export Report'
  | 'Export Report'
  | 'My Task'
  | 'Prospect Overview'
  | 'Search Marketing Campaign'
  | 'Search Mass Campaign'
  | 'Search Agency Campaign'
  | 'Search Servicing Campaign'
  | 'Opportunity'
  | 'Export Leads'
  | 'View Dialogue'
  | 'Export Leads in Campaign'
  | 'Export Prospects'
  | 'View Export Report';

export type GAStage =
  | 'NA'
  | 'All'
  | 'start'
  | 'sso_login'
  | 'password_login'
  | 'login_success'
  | 'login_fail'
  | 'view_agent_profile'
  | 'create_agent_profile'
  | 'edit_contact_info'
  | 'edit_self_introduction'
  | 'edit_awards'
  | 'add_awards'
  | 'view_full_awards'
  | 'add_promotions'
  | 'edit_promotions'
  | 'switch_language'
  | 'edit_contact_info'
  | 'edit_contact_info'
  | 'save_success'
  | 'create_success'
  | 'return_to_home'
  | 'view_overview'
  | 'view_prospect_category'
  | 'view_prospect_source'
  | 'create_campaign'
  | 'submit'
  | 'import_participant'
  | 'select_participant'
  | 'select_campaign_type'
  | 'download'
  | 'result'
  | 'preview'
  | 'fill_agt_survey'
  | 'view'
  | 'PRULeads Home'
  | 'new lead reminder'
  | 'aging pending accept lead reminder'
  | 'Aging Pending Accept Lead Reminder'
  | 'PRULeads Opportunity List'
  | 'Prospect Detail Lead List'
  | 'Contact Lead Reminder'
  | 'contact lead reminder'
  | 'Lead Auto Drop Reminder'
  | 'PRULeads Follow Up List'
  | 'Marketing Campaign Lead List'
  | 'Agency Campaign Lead List'
  | 'Insight Lead Detail Lead List'
  | 'PRULeads Close Lead List'
  | 'accept'
  | 'confirm_update_lead'
  | 'update_status'
  | 'Prospect Details'
  | 'View Lead Detail'
  | 'Edit Sales Lead'
  | 'Reassign Lead Reminder'
  | 'Select Unit'
  | 'Select Lead'
  | 'Edit Lead Status'
  | 'Select Agent'
  | 'start'
  | 'Handle With Care'
  | 'Agency Campaign Detail'
  | 'Add Lead'
  | 'Prospect List'
  | 'Search Prospect'
  | 'Single Delete Prospect'
  | 'Auto Purge Prospect Reminder'
  | 'Single Add Prospect'
  | 'Import Existing Customer As Prospect'
  | 'Merge Prospect'
  | 'Bulk Edit Category'
  | 'Bulk Delete Prospect'
  | 'View Campaign List'
  | 'View Marketing Campaign Detail'
  | 'View Mass Campaign Detail'
  | 'Mass Campaign Lead List'
  | 'View Agency Campaign Detail'
  | 'Create Agency Campaign Application'
  | 'View Approval'
  | 'Post Event Document'
  | 'Lead Management'
  | 'View Campaign Dashboard'
  | 'View Servicing Campaign Detail'
  | 'Insight Home'
  | 'Insight Lead Status Detail'
  | 'Insight Lead Status Metrics Detail'
  | 'Insight Marketing Lead'
  | 'Insight Mass Campaign'
  | 'Insight Agency Campaign Lead'
  | 'Insight Prospect'
  | 'Insight Prospect Metrics Detail'
  | 'Insight Appointment Detail'
  | 'Insight Servicing Campaign Detail'
  | 'Insight Export Report'
  | 'Feedback'
  | 'My Task Detail'
  | 'Insight Lead Follow Up Status Metrics Detail'
  | 'Insight Lead Dead Reason Metrics Detail'
  | 'View Prospect Overview'
  | 'Search'
  | 'Sorting'
  | 'Filter'
  | 'Insight Lead Policy Issued Metrics Detail'
  | 'Export Leads'
  | 'AI Talkbot Page Landing'
  | 'View Eligible Leads'
  | 'Eligible Leads Page Landing'
  | 'View Eligible Opportunities'
  | 'View Eligible Follow Up Leads'
  | 'View Eligible Autodrop Leads'
  | 'Click Dialogue Card'
  | 'Click on Campaign'
  | 'View Eligible Prospects'
  | 'Export History Landing'
  | 'Export Details Landing'
  | 'Filter Exported Records'
  | 'View Exported Record Details'
  | 'Play Recording'
  | 'View Transcript'
  | 'Export Eligible Prospects'
  | 'Confirm Export Tnc';

export type GAScreenName =
  | 'CallbackPage'
  | 'CreateAgentProfilePageScreen'
  | 'EditAgentProfilePageScreen'
  | 'GetInTouchWithMePageScreen'
  | 'SubmitSuccessPageScreen'
  | 'CreateSuccessPageScreen'
  | 'AboutMePageScreen'
  | 'AwardMorePageScreen'
  | 'AwardPageScreen'
  | 'PromotionPageScreen'
  | 'ErrorNotFoundPage'
  | 'ErrorForbiddenPage'
  | 'ErrorV2Page'
  | 'ErrorV4Page'
  | 'ErrorV5Page'
  | 'ErrorV6Page'
  | 'LoginPage'
  | 'CreateCampaignPage'
  | 'CampaignDetailPage'
  | 'CampaignListPage'
  | 'PRULeadsOverviewPage'
  | 'PRULeadsDetailPageProspectCategory'
  | 'PRULeadsDetailPageProspectSource'
  | 'DashboardPage'
  | 'DownloadApplicationPage'
  | 'InsuranceSummaryPreviewPage'
  | 'InsuranceSummaryFormPage'
  | 'SummaryPage'
  | 'AgentSurveyPage'
  | 'CustomerSurveyPreviewPage'
  | 'CustomerSurveyResultPage'
  | 'SingleSignOnPage'
  | 'PRULeads Home'
  | 'PRULeads Home Screen'
  | 'you got a new opportunity'
  | 'these lead are getting cold'
  | 'PRULeads Opportunity List'
  | 'Prospect Detail'
  | 'PRULeads Follow Up List'
  | 'Successfully Contacted Lead Yesterday'
  | 'Marketing Campaign Details'
  | 'Agency Campaign Details'
  | 'Insight Lead Details'
  | 'Lead Auto Drop List'
  | 'PRULeads Close Lead List'
  | 'All'
  | 'Prospect Details'
  | 'PRULeads Home Page'
  | 'Sales Lead Detail'
  | 'Edit Sales Lead'
  | 'Reassign Lead Select Unit'
  | 'Reassign Lead Select Lead'
  | 'Reassign Lead Select Agent'
  | 'Double Confirm turn off accept lead'
  | 'Handle With Care Reminder'
  | 'Agency Campaign Detail'
  | 'Add Lead'
  | 'Prospect List'
  | 'Search Prospect'
  | 'Single Add Prospect'
  | 'Import Existing Customer As Prospect'
  | 'Search Campaign'
  | 'Campaign List'
  | 'Marketing Campaign Detail'
  | 'Mass Campaign Detail'
  | 'Mass Campaign Details'
  | 'Campaign Dashboard'
  | 'Servicing Campaign Detail'
  | 'Insight Home'
  | 'Insight Lead Status Detail'
  | 'Insight Lead Status Metrics Detail'
  | 'Insight Marketing Lead Detail'
  | 'Insight Mass Campaign Detail'
  | 'Insight Agency Campaign Lead Detail'
  | 'Insight Prospect Detail'
  | 'Insight Prospect Metrics Detail'
  | 'Insight Appointment Detail'
  | 'Insight Servicing Campaign Detail'
  | 'Insight Export Report'
  | 'Sales Export Report Feedback Screen'
  | 'My Task Detail'
  | 'Reassign Lead'
  | 'Lead Details'
  | 'Sales View Prospect'
  | 'Marketing Campaign List'
  | 'Mass Campaign List'
  | 'Agency Campaign List'
  | 'Servicing Campaign List'
  | 'Insight Lead Follow Up Status Metrics Detail'
  | 'Insight Lead Dead Reason Metrics Detail'
  | 'Insight Lead Policy Issued Metrics Detail'
  | 'Auto Call Service Dialog'
  | 'AI Talkbot Export Leads'
  | 'Eligible Leads'
  | 'Auto Drop List'
  | 'Export History'
  | 'Export Details'
  | 'Recording & Transcript Details'
  | 'Campaign Details'
  | 'Export to AI Talkbot Dialog';

export const objectTypeShortFormMap = {
  BUTTON: 'BTN',
  Button: 'BTN',
  Tab: 'TAB',
  Card: 'Card',
  Link: 'Link',
  Icon: 'Icon',
};

export type GAObjectName =
  | 'Check'
  | 'Close'
  | 'Accept'
  | 'Reject'
  | 'Accept All'
  | 'Remove All'
  | 'FollowUp'
  | 'Update Lead'
  | 'Search'
  | 'Sorting';

export type GAObjectType = 'Button' | 'BUTTON' | 'Tab' | 'Card' | 'Link' | 'Icon';
// export type GAObjectType = keyof typeof objectTypeShortFormMap;

export type GAEventSource = 'PRUForce-AgentPortal-IN-web' | 'PRUForce-AgentPortal-IN-webapp';
