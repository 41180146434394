import { useCallback, useEffect } from 'react';
import { useRequest } from 'ahooks';

import { getExportDetailAndAudio } from 'src/app/modules/ai-talkbot/network/talkbot-export-history-crud';
import { Translation } from 'src/app/i18n';
import { takeUIScreenViewEvent } from 'src/app/common/ga/ga';
import { recordingTranscriptDetailsGaDataRoute } from 'src/app/common/ga/types/ga-talkbot';

interface HookProps {
  id: number;
}

export const useViewLog = ({ id }: HookProps) => {
  const { data, error, loading, run } = useRequest(() => getExportDetailAndAudio({ callId: id }), { manual: true });

  useEffect(() => {
    if (id) {
      run();
      takeUIScreenViewEvent(recordingTranscriptDetailsGaDataRoute);
    }
  }, [id]);

  const getRoleLabel = useCallback(
    (role: string) => {
      if (role === 'robot') {
        return Translation('title.talkbot');
      }
      return data?.displayName || 'Customer';
    },
    [data?.displayName],
  );

  return {
    loading,
    audio: data?.audio?.data,
    transcript: data?.dialogueDetail,
    run,
    getRoleLabel,
  };
};
