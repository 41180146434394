import React, { FC, useState, useMemo } from 'react';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import { useTranslation } from 'src/app/common/hooks/translation.hook';
import { useDispatch } from 'react-redux';
import { Box, Button, FormControlLabel, LinearProgress, Radio, RadioGroup, TextField, Typography } from '@mui/material';
import { ParamsProps } from 'src/app/common/components/ParamsProvider';
import { Page, PageTitle } from 'src/app/common/components/page/page.component';
import HeaderBreadCrumbs from 'src/app/common/components/layout/components/container/components/header/header-breadcrumbs';
import { FilterTableContainer } from 'src/app/common/components/filter-table-container/filter-table-container.component';
import { useAutoDropList } from './auto-drop-list.hook';
import { MultiExpoButton } from '../../../common/components/talkbot-export-button/talkbot-export-button.component';
import { eligibleLeadsGaDataClick3, exportToAiTalkbotDialogGaDataClick } from 'src/app/common/ga/types/ga-talkbot';

type PageProps = ParamsProps &
  RouteComponentProps & {
    /* otherProp: string */
  };

export const AutoDropListPage: React.FC<PageProps> = (props: PageProps) => {
  const history = useHistory();
  const { t } = useTranslation();

  // custom-hook
  const { getTalkbotAutoDropListColumns, reminderAutoDropLeadDay, getData } = useAutoDropList();

  return (
    <Page>
      <HeaderBreadCrumbs />
      <PageTitle
        title={t('lead.autoDrop.list.header')}
        rightNode={
          <Button variant="outlined" color="info" onClick={history.goBack}>
            {t('app.button.back')}
          </Button>
        }
      />
      <Typography marginTop={'24px'} marginBottom={'24px'}>
        {t('lead.autoDrop.list.description', { day: reminderAutoDropLeadDay ?? 7 })}
      </Typography>
      <FilterTableContainer
        rowKey="_id"
        getData={getData}
        getColumns={getTalkbotAutoDropListColumns}
        showFilter={false}
        ActionNode={(props) => (
          <MultiExpoButton
            {...props}
            gaData={{ button: eligibleLeadsGaDataClick3, confirm: exportToAiTalkbotDialogGaDataClick }}
          />
        )}
      />
    </Page>
  );
};
