import React, { ReactNode, memo, useMemo, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { Box, Button, SxProps, Theme, Tooltip, Typography } from '@mui/material';

import { useStyles } from './action-items.style';
import EditIcon from '@mui/icons-material/Edit';

import { useDispatch } from 'react-redux';
import { Dialog } from 'src/app/common/components/Dialog/dialog/dialog.component';
import { CampaignLeadItem } from 'src/app/modules/Campaign/types/campaign-types';
import { ActionNodeTypeProps } from 'src/app/common/components/ProTable';
import { campaignAcceptLeads } from 'src/app/modules/Campaign/network/campaignCurd';
import { appendAlertItem, AlertType } from 'src/redux/common/commonSlice';
import { MultiExpoButton } from 'src/app/modules/ai-talkbot/common/components/talkbot-export-button/talkbot-export-button.component';
import PruIcon from 'src/app/common/components/PruIcon';
import { campaignDetailsGaDataClick, exportToAiTalkbotDialogGaDataClick2 } from 'src/app/common/ga/types/ga-talkbot';

export type ActionNodeProps = ActionNodeTypeProps<CampaignLeadItem> & {
  onAutoCall: (leads: CampaignLeadItem[]) => void;
};

export const ActionItems: React.FC<ActionNodeProps> = memo(
  ({ selectedRows, onAction, onAutoCall }: ActionNodeProps) => {
    const intl = useIntl();
    const Translation = (id: string, values?: any) => intl.formatMessage({ id }, values);
    const { classes } = useStyles();
    const styles = useMemo(() => classes, [classes]);
    const [open, setOpen] = useState(false);
    const dispatch = useDispatch();

    const disabled = useMemo(() => {
      if (selectedRows?.some((i) => i.status !== 'pending')) return true;
      else return false;
    }, [selectedRows]);

    const acceptSeleted = async () => {
      const leadIds = selectedRows.map((item) => item._id);
      try {
        onAutoCall(selectedRows);
        const result = await campaignAcceptLeads(leadIds);
        onAction(['refresh', 'clearRow']);
        dispatch(
          appendAlertItem([
            {
              severity: AlertType.SUCCESS,
              title: Translation('global.submit.success'),
              content: Translation('lead.accepted.successfully'),
            },
          ]),
        );
      } catch (err) {
        dispatch(
          appendAlertItem([
            {
              severity: AlertType.ERROR,
              title: Translation('component.status.failed'),
              content: Translation('common.pleaseTryAgain'),
            },
          ]),
        );
      }
    };

    return (
      <Box>
        <Tooltip
          title={Translation('lead.acceptLeads.tooltips')}
          disableHoverListener={!disabled}
          disableTouchListener={!disabled}
        >
          {
            <>
              <Button
                onClick={() => !disabled && setOpen(true)}
                variant="text"
                className={`${styles.iconButtonText} ${disabled ? styles.disabled : styles.enable}`}
              >
                <PruIcon icon="done" customClasses="tw-w-[24px] tw-h-[24px] tw-inline tw-mb-[3px]" />
                {Translation('app.button.accept')}
              </Button>
              <MultiExpoButton
                selectedRows={selectedRows}
                onAction={onAction}
                gaData={{ button: campaignDetailsGaDataClick, confirm: exportToAiTalkbotDialogGaDataClick2 }}
              />
            </>
          }
        </Tooltip>
        {open && (
          <Dialog
            title={Translation('campaign.marketing.detail.leads.acceptDialog.title')}
            content={Translation('campaign.marketing.detail.leads.acceptDialog.desc')}
            open={open}
            onConfirm={() => {
              acceptSeleted();
              setOpen(false);
            }}
            onClose={() => setOpen(false)}
          />
        )}
      </Box>
    );
  },
);
