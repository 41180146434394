import { IntlShape } from 'react-intl';
import { ModuleHierachy } from 'src/app/common/module/types';
import {
  AI_TALKBOT_BASE_PATH,
  AI_TALKBOT_EXPORT_DETAIL_TITLE,
  AI_TALKBOT_EXPORT_HISTORY_NAMESPACE,
  AI_TALKBOT_EXPORT_HISTORY_PATH,
  AI_TALKBOT_EXPORT_HISTORY_TITLE,
  AI_TALKBOT_EXPORT_LEADS_AUTO_DROP_LIST_TITLE,
  AI_TALKBOT_EXPORT_LEADS_LIST_TITLE,
  AI_TALKBOT_EXPORT_LEADS_NAMESPACE,
  AI_TALKBOT_EXPORT_LEADS_PATH,
  AI_TALKBOT_EXPORT_LEADS_TITLE,
  AI_TALKBOT_NAMESPACE,
  AI_TALKBOT_TITLE,
  DETAIL_PATH,
  LIST_PATH,
} from './constants';
import ExportLeadsRoutes from './pages/export-leads/export-leads-routes';
import ExportHistoryRoutes from './pages/export-history/export-history-routes';
import { get } from 'lodash';
import { APP_CONFIG, getAppConfigs } from 'src/app/common/utils';

export const aiTalkbotModuleConfig = (permissions: string[], intl: IntlShape): ModuleHierachy => {
  const Translation = (id: string) => intl.formatMessage({ id });
  const enableTalkbot = get(getAppConfigs(APP_CONFIG.SALES_SDK), 'Sales.lead.enableAgentPortalTalkbot', false);
  return {
    title: Translation(AI_TALKBOT_TITLE),
    path: AI_TALKBOT_BASE_PATH,
    namespace: AI_TALKBOT_NAMESPACE,
    disableMenu: false,
    disableRoleControl: true,
    enableRead: enableTalkbot,
    enableCreate: true,
    enableUpdate: true,
    child: [
      {
        title: Translation(AI_TALKBOT_EXPORT_LEADS_TITLE),
        path: `${AI_TALKBOT_BASE_PATH}${AI_TALKBOT_EXPORT_LEADS_PATH}`,
        component: ExportLeadsRoutes,
        namespace: AI_TALKBOT_EXPORT_LEADS_NAMESPACE,
        enableRead: enableTalkbot,
        enableCreate: true,
        enableUpdate: true,
        page: [
          {
            title: Translation(AI_TALKBOT_EXPORT_LEADS_LIST_TITLE),
            path: `${AI_TALKBOT_BASE_PATH}${AI_TALKBOT_EXPORT_LEADS_PATH}${LIST_PATH}`,
            page: [
              {
                title: Translation(AI_TALKBOT_EXPORT_LEADS_AUTO_DROP_LIST_TITLE),
                path: `${AI_TALKBOT_BASE_PATH}${AI_TALKBOT_EXPORT_LEADS_PATH}${LIST_PATH}/auto-drop`,
              },
            ],
          },
        ],
      },
      {
        title: Translation(AI_TALKBOT_EXPORT_HISTORY_TITLE),
        path: `${AI_TALKBOT_BASE_PATH}${AI_TALKBOT_EXPORT_HISTORY_PATH}`,
        namespace: AI_TALKBOT_EXPORT_HISTORY_NAMESPACE,
        component: ExportHistoryRoutes,
        enableRead: enableTalkbot,
        enableCreate: true,
        enableUpdate: true,
        page: [
          {
            title: Translation(AI_TALKBOT_EXPORT_DETAIL_TITLE),
            path: `${AI_TALKBOT_BASE_PATH}${AI_TALKBOT_EXPORT_HISTORY_PATH}${DETAIL_PATH}`,
          },
        ],
      },
    ],
  };
};
