import moment from 'moment';
import { ProColumns } from 'src/app/common/components/ProTable';
import {
  aITalkbotExportLeadsGaDataClick5,
  exportToAiTalkbotDialogGaDataClick3,
} from 'src/app/common/ga/types/ga-talkbot';
import { DateFormat, formatDate } from 'src/app/common/utils';
import { renderDateTime } from 'src/app/modules/AgencyCampaign/pages/Campaign/campaign-list/util/list.util';
import { renderExpoButton } from 'src/app/modules/ai-talkbot/common/components/talkbot-export-button/talkbot-export-button.component';
import { TalkBotExportType } from 'src/app/modules/ai-talkbot/types/talkbot-export-leads-types';
import { ProspectCategory } from 'src/app/modules/Prospect/types/prospect-types';
import { renderCategory, renderGender, renderSource, renderTag } from 'src/app/modules/Prospect/util/table-column.util';

export const getProspectColumns = ({
  t,
  prospectCategories,
  flowId,
}: {
  t: (key: string, values?: Record<string, any>) => string;
  prospectCategories?: ProspectCategory[];
  flowId?: number;
}): ProColumns<any>[] => [
  {
    title: t('pruleads.prospect.list.column.name'),
    dataIndex: 'displayName',
    sorter: true,
    width: '290px',
    render: (currValue, item) => item.prospect.displayName || item.content.clientName,
  },
  {
    title: t('pruleads.prospect.list.column.birthday'),
    dataIndex: 'birthDate',
    sorter: true,
    width: '176px',
    render: (currValue, item) => {
      const value = getValue(item, 'birthDate');
      return value ? formatDate(value, DateFormat.date) : value;
    },
  },
  {
    title: t('pruleads.prospect.list.column.age'),
    dataIndex: 'birthDate',
    width: '176px',
    render: (currValue, item) => {
      return moment().diff(moment(getValue(item, 'birthDate')), 'years');
    },
  },
  {
    title: t('pruleads.prospect.list.column.gender'),
    dataIndex: 'gender',
    width: '176px',
    render: (currValue: 'F' | 'M', item) => renderGender(getValue(item, 'gender'), t),
  },
  {
    title: t('pruleads.prospect.list.column.prospectType'),
    dataIndex: 'types',
    width: '176px',
    render: (currValue, item) => renderTag(getValue(item, 'types')),
  },
  {
    title: t('pruleads.prospect.list.column.source'),
    dataIndex: 'sourceType',
    width: '176px',
    render: (currValue, item) => renderSource(item.prospect.sourceType, t),
  },
  {
    title: t('pruleads.prospect.list.column.category'),
    dataIndex: 'prospectCategory',
    width: '176px',
    render: (currValue, item) => renderCategory(item.prospect.prospectCategory, prospectCategories),
  },
  {
    title: t('section.common.operation'),
    sticky: true,
    dataIndex: 'operation',
    width: '176px',
    render: (currValue, item, actions) =>
      renderExpoButton({
        item,
        type: TalkBotExportType.prospect,
        actions,
        gaData: { button: aITalkbotExportLeadsGaDataClick5, confirm: exportToAiTalkbotDialogGaDataClick3 },
      }),
  },
];

const getValue = (item: any, key: string) => {
  return item.prospect[key] || item.content[key];
};
