import { GAData as RouteGA } from '../redux/gaSlice';
import { TakeUIClickEventParameters } from './ga-general-type';

// screen
const aITalkbotExportLeadsGaDataRoute: RouteGA = {
  module: 'AI Talkbot',
  feature: 'AI Talkbot',
  journey: 'Export Leads',
  stage: 'AI Talkbot Page Landing',
  screen_name: 'AI Talkbot Export Leads',
};

const eligibleLeadsGaDataRoute: RouteGA = {
  module: 'AI Talkbot',
  feature: 'AI Talkbot',
  journey: 'Export Leads',
  stage: 'Eligible Leads Page Landing',
  screen_name: 'Eligible Leads',
};

const autoDropListGaDataRoute: RouteGA = {
  module: 'AI Talkbot',
  feature: 'AI Talkbot',
  journey: 'Export Leads',
  stage: 'View Eligible Autodrop Leads',
  screen_name: 'Auto Drop List',
};

const exportHistoryGaDataRoute: RouteGA = {
  module: 'AI Talkbot',
  feature: 'AI Talkbot',
  journey: 'View Export Report',
  stage: 'Export History Landing',
  screen_name: 'Export History',
};

const exportDetailsGaDataRoute: RouteGA = {
  module: 'AI Talkbot',
  feature: 'AI Talkbot',
  journey: 'View Export Report',
  stage: 'Export Details Landing',
  screen_name: 'Export Details',
};

const recordingTranscriptDetailsGaDataRoute: RouteGA = {
  module: 'AI Talkbot',
  feature: 'AI Talkbot',
  journey: 'View Export Report',
  stage: 'View Transcript',
  screen_name: 'Recording & Transcript Details',
};

// click event:
const autoCallServiceDialogGaDataClick: TakeUIClickEventParameters = {
  module: 'PRULeads',
  feature: 'Lead',
  journey: 'Opportunity',
  stage: 'Export Leads',
  screen_name: 'Auto Call Service Dialog',
  object_name: 'Yes',
  object_type: 'Button',
};

const aITalkbotExportLeadsGaDataClick: TakeUIClickEventParameters = {
  module: 'AI Talkbot',
  feature: 'AI Talkbot',
  journey: 'Export Leads',
  stage: 'View Eligible Leads',
  screen_name: 'AI Talkbot Export Leads',
  object_name: 'Leads Eligible for AI Talkbot Export',
  object_type: 'Card',
};

const eligibleLeadsGaDataClick: TakeUIClickEventParameters = {
  module: 'AI Talkbot',
  feature: 'AI Talkbot',
  journey: 'Export Leads',
  stage: 'View Eligible Opportunities',
  screen_name: 'Eligible Leads',
  object_name: 'Opportunities',
  object_type: 'Button',
};

const eligibleLeadsGaDataClick2: TakeUIClickEventParameters = {
  module: 'AI Talkbot',
  feature: 'AI Talkbot',
  journey: 'Export Leads',
  stage: 'View Eligible Follow Up Leads',
  screen_name: 'Eligible Leads',
  object_name: 'Follow Up',
  object_type: 'Button',
};

const eligibleLeadsGaDataClick3: TakeUIClickEventParameters = {
  module: 'AI Talkbot',
  feature: 'AI Talkbot',
  journey: 'Export Leads',
  stage: 'Export Leads',
  screen_name: 'Eligible Leads',
  object_name: 'Export',
  object_type: 'Button',
};

const aITalkbotExportLeadsGaDataClick2: TakeUIClickEventParameters = {
  module: 'AI Talkbot',
  feature: 'AI Talkbot',
  journey: 'View Dialogue',
  stage: 'Click Dialogue Card',
  screen_name: 'AI Talkbot Export Leads',
  object_name: 'Dialogue',
  object_type: 'Card',
};

const aITalkbotExportLeadsGaDataClick3: TakeUIClickEventParameters = {
  module: 'AI Talkbot',
  feature: 'AI Talkbot',
  journey: 'Export Leads in Campaign',
  stage: 'Click on Campaign',
  screen_name: 'AI Talkbot Export Leads',
  object_name: 'Campaign',
  object_type: 'Link',
};

const campaignDetailsGaDataClick: TakeUIClickEventParameters = {
  module: 'AI Talkbot',
  feature: 'AI Talkbot',
  journey: 'Export Leads in Campaign',
  stage: 'Export Leads',
  screen_name: 'Campaign Details',
  object_name: 'Export',
  object_type: 'Button',
};

const aITalkbotExportLeadsGaDataClick4: TakeUIClickEventParameters = {
  module: 'AI Talkbot',
  feature: 'AI Talkbot',
  journey: 'Export Prospects',
  stage: 'View Eligible Prospects',
  screen_name: 'AI Talkbot Export Leads',
  object_name: 'Prospects',
  object_type: 'Button',
};

const aITalkbotExportLeadsGaDataClick5: TakeUIClickEventParameters = {
  module: 'AI Talkbot',
  feature: 'AI Talkbot',
  journey: 'Export Prospects',
  stage: 'Export Eligible Prospects',
  screen_name: 'AI Talkbot Export Leads',
  object_name: 'Export',
  object_type: 'Button',
};

const exportDetailsGaDataClick: TakeUIClickEventParameters = {
  module: 'AI Talkbot',
  feature: 'AI Talkbot',
  journey: 'View Export Report',
  stage: 'Filter Exported Records',
  screen_name: 'Export Details',
  object_name: 'Filter',
  object_type: 'Button',
};

const exportDetailsGaDataClick2: TakeUIClickEventParameters = {
  module: 'AI Talkbot',
  feature: 'AI Talkbot',
  journey: 'View Export Report',
  stage: 'View Exported Record Details',
  screen_name: 'Export Details',
  object_name: 'Prospect ',
  object_type: 'Link',
};

const exportDetailsGaDataClick3: TakeUIClickEventParameters = {
  module: 'AI Talkbot',
  feature: 'AI Talkbot',
  journey: 'View Export Report',
  stage: 'Play Recording',
  screen_name: 'Export Details',
  object_name: 'Play Recording',
  object_type: 'Icon',
};

const exportToAiTalkbotDialogGaDataClick: TakeUIClickEventParameters = {
  module: 'AI Talkbot',
  feature: 'AI Talkbot',
  journey: 'Export Leads',
  stage: 'Confirm Export Tnc',
  screen_name: 'Export to AI Talkbot Dialog',
  object_name: 'Confirm',
  object_type: 'Button',
};

const exportToAiTalkbotDialogGaDataClick2: TakeUIClickEventParameters = {
  module: 'AI Talkbot',
  feature: 'AI Talkbot',
  journey: 'Export Leads in Campaign',
  stage: 'Confirm Export Tnc',
  screen_name: 'Export to AI Talkbot Dialog',
  object_name: 'Confirm',
  object_type: 'Button',
};

const exportToAiTalkbotDialogGaDataClick3: TakeUIClickEventParameters = {
  module: 'AI Talkbot',
  feature: 'AI Talkbot',
  journey: 'Export Prospects',
  stage: 'Confirm Export Tnc',
  screen_name: 'Export to AI Talkbot Dialog',
  object_name: 'Confirm',
  object_type: 'Button',
};

export {
  aITalkbotExportLeadsGaDataRoute,
  eligibleLeadsGaDataRoute,
  autoDropListGaDataRoute,
  exportHistoryGaDataRoute,
  exportDetailsGaDataRoute,
  recordingTranscriptDetailsGaDataRoute,
  autoCallServiceDialogGaDataClick,
  aITalkbotExportLeadsGaDataClick,
  eligibleLeadsGaDataClick,
  eligibleLeadsGaDataClick2,
  eligibleLeadsGaDataClick3,
  aITalkbotExportLeadsGaDataClick2,
  aITalkbotExportLeadsGaDataClick3,
  aITalkbotExportLeadsGaDataClick4,
  exportDetailsGaDataClick,
  exportDetailsGaDataClick2,
  exportDetailsGaDataClick3,
  campaignDetailsGaDataClick,
  aITalkbotExportLeadsGaDataClick5,
  exportToAiTalkbotDialogGaDataClick,
  exportToAiTalkbotDialogGaDataClick2,
  exportToAiTalkbotDialogGaDataClick3,
};
