import React, { FC, useEffect, useState } from 'react';
import {
  FormControl,
  Select,
  SelectChangeEvent,
  TextField,
  Button,
  CircularProgress,
  SxProps,
  Theme,
} from '@mui/material';
import { useUpdateEffect } from './hook';
import useFilterStyles from '../../styles/filter-styles';
import { useIntl } from 'react-intl';
import { ProColumns } from '.';
import RangePicker from './RangePicker';
import { useCommonStyles } from '../../styles/common-styles';
import { ListFilterProps } from 'src/app/common/components/list-filter/list-filter.component';

export type FilterProps<T> = {
  title?: string;
  // onChangeFilter: (filterState: FilterState) => void;
  filterState: FilterState;
  setFilterState: React.Dispatch<any>;
  onSave: (filterState: FilterState) => void;
  columns: ProColumns<T>[];
  hideReset?: boolean;
  total?: number;
  loading?: boolean;
  showFilter?: boolean;
  showInput?: boolean;
  sx?: SxProps<Theme>;
  extraFilter?: ListFilterProps['filterValues'];
  onExtraFilterChange?: (val: Record<string, any>) => void;
  filterConfigs?: ListFilterProps['filterConfigs'];
  placeholder?: string;
  onFocus?: (val: any) => void;
  onClickFilterIcon?: () => void;
};

export type FilterState = {
  [key: string]: any;
};

export const incentiveInitFilterState: FilterState = {};

// type ModifyFilterAction = {
//   type: 'CHANGE_FILTER',
//   payload: {
//     field: keyof FilterState,
//     value: string;
//   }
// };

// type ResetFilterAction = {
//   type: 'RESET_FILTER';
// };

// type FilterAction = ModifyFilterAction | ResetFilterAction;

// const filterReducer = (state: FilterState, action: FilterAction) => {
//   switch (action.type) {
//     case 'CHANGE_FILTER': {
//       return {
//         ...state,
//         [action.payload.field]: action.payload.value
//       }
//     }
//     case 'RESET_FILTER': {
//       const result: any = {}
//       // dateb range
//       Object.entries(state).forEach(([key, value]) => {
//         result[key] = ''
//       })
//       return result
//     }
//   }
// }

const AsyncSelect: FC<{
  value: string;
  onChange: (event: React.ChangeEvent<any>) => void;
  request: () => Promise<{ label: string; value: string; key: number }[]>;
  marginRight: number;
}> = ({ request, value, onChange, marginRight, ...restProps }) => {
  const [options, setOptions] = useState<{ label: string; value: string; key: number }[]>([]);
  const [isLoaded, setIsLoaded] = useState<boolean>(false);

  useEffect(() => {
    request()
      .then((res) => {
        setOptions(res);
        setIsLoaded(true);
      })
      .catch((err) => {
        console.error(err);
        setIsLoaded(true);
      });
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <FormControl margin="dense" variant="outlined" style={{ marginRight }}>
        {isLoaded ? (
          <Select
            native
            value={value}
            onChange={onChange as (event: SelectChangeEvent<any>) => void}
            {...restProps}
            inputProps={{
              id: 'filled-age-native-simple',
            }}
          >
            {options.map(({ label, value }) => (
              <option key={value} value={value}>
                {label}
              </option>
            ))}
          </Select>
        ) : (
          <span style={{ width: '166px' }}>
            <CircularProgress size="small" style={{ width: '20px' }} />
          </span>
        )}
        {/* <CircularProgress size="small" style={{ width: '20px' }} /> */}
      </FormControl>
    </>
  );
};

const createFilterItem = <T extends { [key: string]: any } = {}>(
  columns: ProColumns<T>,
  initState: any,
  updateFilter: (key: string) => (e: React.ChangeEvent<any>) => void,
  updateDate: (key: string) => (value: any) => void,
  marginRight = 20,
) => {
  const { valueType, valueEnum, title, dataIndex = '', request } = columns;
  // if (!dataIndex || valueType === 'index' || valueType === 'option' || hideInSearch) return null
  if (valueEnum) {
    return (
      <>
        <span className="PruFilter-criteria">{`${title ?? ''} :`}</span>
        <FormControl margin="dense" variant="outlined" style={{ marginRight }}>
          <Select
            native
            value={initState[dataIndex] || ''}
            onChange={updateFilter(dataIndex) as (event: SelectChangeEvent<any>) => void}
            inputProps={{
              name: 'incentiveStatus',
              id: 'filled-age-native-simple',
            }}
          >
            {Object.entries(valueEnum).map(([value, { text }]) => (
              <option key={value} value={value}>
                {text}
              </option>
            ))}
          </Select>
        </FormControl>
      </>
    );
  } else if (valueType === 'dateRange') {
    return (
      <>
        <span className="PruFilter-criteria">{`${title} :`}</span>
        <div style={{ marginRight }}>
          <RangePicker value={initState[dataIndex] || ''} onChange={updateDate(dataIndex)} />
        </div>
      </>
    );
  } else if (request) {
    return (
      <>
        <span className="PruFilter-criteria">{`${title} :`}</span>
        <AsyncSelect
          marginRight={marginRight}
          value={initState[dataIndex] || ''}
          onChange={updateFilter(dataIndex)}
          request={request}
        />
      </>
    );
  }
  return (
    <>
      <span className="PruFilter-criteria">{`${title} :`}</span>
      <TextField
        style={{ marginRight }}
        margin="dense"
        variant="outlined"
        value={initState[dataIndex] || ''}
        onChange={updateFilter(dataIndex)}
      />
    </>
  );
};

const createFilter = <T extends { [key: string]: any } = {}>(
  columns: ProColumns<T>[],
  initState: any,
  updateFilter: (key: string) => (e: React.ChangeEvent<any>) => void,
  updateDate: (key: string) => (value: any) => void,
) => {
  const temp = [];
  let item: React.ReactNode[] = [];
  const filters = columns
    .filter((column) => {
      const { valueType, dataIndex, hideInSearch } = column;
      return !(!dataIndex || valueType === 'index' || valueType === 'option' || hideInSearch);
    })
    .map((i, index, arr) => {
      if ((index % 3 === 0 && index !== 0) || index === arr.length) {
        return (
          <React.Fragment key={`filter-item-${index}`}>
            {createFilterItem(i, initState, updateFilter, updateDate, 0)}
          </React.Fragment>
        );
      }
      return (
        <React.Fragment key={`filter-item-${index}`}>
          {createFilterItem(i, initState, updateFilter, updateDate, 20)}
        </React.Fragment>
      );
    });
  for (let i = 0; i < filters.length; i++) {
    // 4 filter item in a row
    if (i % 3 === 0 && i !== 0) {
      item.push(filters[i]);
      temp.push([...item]);
      item = [];
    } else {
      item.push(filters[i]);
    }
  }
  if (item.length) {
    temp.push([...item]);
  }
  return temp.map((el, index) => (
    <div className="PruFilter-row" key={`PruFilter-row-${index}`}>
      {el}
    </div>
  ));
};

const ProFilter = <T extends { [key: string]: any } = {}>({
  title,
  // onChangeFilter,
  filterState,
  setFilterState,
  onSave,
  columns,
  hideReset,
}: FilterProps<T>) => {
  const { classes: commonClasses } = useCommonStyles();
  const { classes: filterClasses } = useFilterStyles();
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });

  // const [filterState, filterDispatch] = useReducer(filterReducer, incentiveInitFilterState);

  const updateFilter = (field: string) => (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    // filterDispatch({ type: 'CHANGE_FILTER', payload: { field, value: e.target.value } })
    const newState: any = { ...filterState };
    newState[field] = e.target.value;
    setFilterState(newState);
  };

  const updateDate = (field: string) => (value: any) => {
    // filterDispatch({ type: 'CHANGE_FILTER', payload: { field, value } })
    const newState: any = { ...filterState };
    newState[field] = value;
    setFilterState(newState);
  };

  useUpdateEffect(() => {
    // onChangeFilter(filterState);
    setFilterState(filterState);
  }, [filterState]);

  const onReset = () => {
    // filterDispatch({ type: 'RESET_FILTER' })
    const newState: { [key: string]: string } = { ...filterState };
    columns.forEach((column) => {
      if (!column.hideInSearch && column.dataIndex) {
        newState[column.dataIndex] = '';
      }
    });
    setFilterState(newState);
  };

  const onSearch = () => {
    onSave(filterState);
  };

  const keyPressSearch = (e: KeyboardEvent) => {
    if (e.key === 'Enter') {
      onSearch();
    }
  };

  useEffect(() => {
    window.addEventListener('keypress', keyPressSearch);
    return () => {
      window.removeEventListener('keypress', keyPressSearch);
    };
    // eslint-disable-next-line
  }, [filterState]);

  const filterItems = createFilter<T>(columns, filterState, updateFilter, updateDate);

  return filterItems.length > 0 ? (
    <div style={{ marginBottom: 20 }} className={filterClasses.root}>
      {/* Header Row */}
      <div style={{ marginBottom: 5 }} className="PruFilter-header-container">
        <div className={commonClasses.header}>{title}</div>
        <div className="PruFilter-row">
          {hideReset ? null : (
            <Button style={{ marginRight: 20 }} variant="contained" color="inherit" onClick={onReset}>
              {Translation('golbal.filters.reset')}
            </Button>
          )}
          <Button variant="contained" color="secondary" onClick={onSearch}>
            {Translation('golbal.filters.search')}
          </Button>
        </div>
      </div>
      {filterItems}
    </div>
  ) : null;
};

export default ProFilter;
