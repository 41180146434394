import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { get } from 'lodash';
import { useLang } from 'src/app/i18n';
import { getExportDetail } from '../../../network/talkbot-export-history-crud';
import { getDayEnd, getDayStart } from 'src/app/common/utils';
import { useHistory } from 'react-router-dom';
import { LEADS_DETAIL_PATH } from 'src/app/modules/Leads/constants';
import { PROSPECT_BASE_PATH, PROSPECT_DETAIL_PATH } from 'src/app/modules/Prospect/constants';
import { takeUIClickEvent, takeUIScreenViewEvent } from 'src/app/common/ga/ga';
import { exportDetailsGaDataClick2, exportDetailsGaDataRoute } from 'src/app/common/ga/types/ga-talkbot';

interface HookProps {
  date: string;
  // other params
}

export const useDetail = ({ date }: HookProps) => {
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    takeUIScreenViewEvent(exportDetailsGaDataRoute);
  }, []);

  const getData = useCallback(
    async (
      params: { page: number; pageSize: number; current?: number | undefined; keyword?: string | undefined },
      sort?: { [key: string]: any },
      filter?: { [key: string]: any },
    ) => {
      const { interest, actionItems, leadStatus, callStatus } = filter ?? {};
      let contactTagsArr = interest || actionItems || leadStatus ? [] : undefined;
      if (contactTagsArr) {
        contactTagsArr = contactTagsArr.concat(interest).concat(actionItems).concat(leadStatus);
      }
      const { page, pageSize } = params;
      const formatDate = new Date(date);
      let sortBy = 'exportDate:-1';
      if (sort && Object.keys(sort).length > 0) {
        const [key, val] = Object.entries(sort)[0];
        sortBy = val === 'desc' ? `${key}:-1` : `${key}:1`;
      }
      const res = await getExportDetail(
        {
          page: page + 1,
          limit: pageSize,
          createdAtGte: getDayStart(formatDate).toISOString(),
          createdAtLte: getDayEnd(formatDate).toISOString(),
          callStatus,
          contactTags: contactTagsArr?.filter((item) => !!item),
          sortBy,
        },
        dispatch,
      );
      const { docs, totalDocs } = res ?? {};
      return {
        success: true,
        data: docs ?? [],
        total: totalDocs ?? 0,
      };
    },
    [],
  );

  const toDetail = useCallback((contactId: string) => {
    const [type, id] = contactId.split('_');
    takeUIClickEvent(exportDetailsGaDataClick2);
    if (type === 'lead') {
      history.push(`${LEADS_DETAIL_PATH}/${id}`, {});
    } else {
      history.push(`${PROSPECT_BASE_PATH}${PROSPECT_DETAIL_PATH}/${id}`);
    }
  }, []);

  return {
    getData,
    toDetail,
  };
};
