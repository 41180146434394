import React, { FC, memo, useMemo } from 'react';
import { useTranslation } from 'src/app/common/hooks/translation.hook';
import { Box, Button, Stack, TextField, Tooltip, Typography } from '@mui/material';
import { ParamsProps } from 'src/app/common/components/ParamsProvider';
import PruDialog from 'src/app/common/components/PruDialog';
import { Info } from '@mui/icons-material';
import { useTalkbotExportButton } from '../talkbot-export-button/talkbot-export-button.hook';
import { Lead } from 'src/app/modules/Leads/types/types';
import PruIcon from 'src/app/common/components/PruIcon';
import { takeUIClickEvent } from 'src/app/common/ga/ga';
import { autoCallServiceDialogGaDataClick } from 'src/app/common/ga/types/ga-talkbot';

type ComponentProps = ParamsProps & {
  /* otherProp: string */
  onCancel: () => void;
  items: Lead[];
};

export const AutoCallDialogComponent: React.FC<ComponentProps> = memo(({ items, onCancel }: ComponentProps) => {
  // i18n
  const { t } = useTranslation();

  const { onExport } = useTalkbotExportButton({ items });

  return (
    <PruDialog
      open={!!items}
      dialogTitle={t('talkbot.auto_call_title')}
      confirmBtnText={t('global.text.yes')}
      canncelBtnText={t('global.text.no')}
      onOk={() => {
        takeUIClickEvent(autoCallServiceDialogGaDataClick);
        onCancel();
        onExport(items);
      }}
      onCancel={onCancel}
    >
      <Box className="tw-w-[392px]">
        <Typography className="tw-text-[#333333]" sx={{ fontSize: 14, fontWeight: '400' }}>
          {t('talkbot.auto_call_desc')}
        </Typography>
        <Stack flexDirection="row" alignItems="center" sx={{ marginTop: 3, marginBottom: 3 }}>
          <Typography className="tw-text-[#e8192c]" sx={{ fontSize: 14, fontWeight: '400' }}>
            {t('talkbot.lead_records')}: {items.length}
          </Typography>
          <Tooltip arrow title={t('talkbot.export_leads.tooltip')}>
            <Info sx={{ color: '#CCCCCC', marginLeft: 0.5, width: 16, height: 16 }} />
          </Tooltip>
        </Stack>
        <Box className="tw-bg-[#F5F5F5]" sx={{ padding: 2, borderRadius: 1.5 }}>
          <Typography sx={{ fontSize: 14, fontWeight: '500', marginBottom: 3 }}>{t('talkbot.pic_tips')}</Typography>
          <Typography sx={{ fontSize: 14, fontWeight: '500', marginTop: 1, marginBottom: 1 }}>
            <PruIcon icon="tip_urgent" customClasses="tw-w-[16px] tw-h-[16px] tw-inline tw-text-[#CCCCCC]" />
            {t('talkbot.auto_call_tips')}
          </Typography>
        </Box>
      </Box>
    </PruDialog>
  );
});
